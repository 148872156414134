<template>
	<div class="category" :class="{ selected: selectedCategory == category.en }" @click="changeCategory(category.en)">
		{{ title }}
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	props: ['category'],
	computed: {
		...mapGetters(['siteLang', 'selectedCategory']),
		title() {
			return this.category[this.siteLang];
		},
	},
	methods: {
		changeCategory(category) {
			this.$store.dispatch('changeCategory', category);
		},
	},
};
</script>

<style scoped>
.category {
	background: #fff;
	color: black;
	border-radius: 10px;
	padding: 0px 9px 4px 9px;
	font-weight: 200;
	cursor: pointer;
	user-select: none;
	transition: all 1s;
}

.category:hover {
	transform: scale(1.05);
}

.selected {
	opacity: 0.9;
	background: linear-gradient(193deg, #556a89 0%, #555e8a 100%);
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	color: white;
	cursor: default;
	transform: scale(1.05);
}
</style>
