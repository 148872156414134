import { nextTick } from 'vue';
import { initializeStore } from './localStorage';

function play(state, payload) {
	try {
		if (payload) {
			const selectedAlbum = { ...state.discography[state.selectedCategory][payload - 1] };

			if (selectedAlbum.unreleased) {
				nextTick(() => {
					document.querySelector('audio')?.pause();
				});
				return;
			}

			const selectedCategoryLength = state.discography[state.selectedCategory].length;

			if (state.currentAlbum[state.selectedCategory] >= selectedCategoryLength - 1) {
				state.currentAlbum[state.selectedCategory] = 0;
			}

			state.currentAlbum[state.selectedCategory] = payload - 3;

			localStorage.setItem(state.selectedCategory, payload - 3);
		}

		const shiftedAlbumID = _shiftAlbumID(state, state.currentAlbum[state.selectedCategory]);

		state.playingAlbum = { [state.selectedCategory]: shiftedAlbumID };
		localStorage.setItem('playingAlbum', shiftedAlbumID);

		state.playPressed = true;
		state.isPlaying = true;
		localStorage.setItem('isPlaying', true);
		localStorage.setItem('playPressed', true);

		nextTick(() => {
			document.querySelector('audio')?.play();
		});
	} catch (e) {
		111;
	}
}

function _shiftAlbumID(state, albumID) {
	const categoryLength = state.discography[state.selectedCategory].length;
	const offset = 2;
	albumID = albumID + offset;

	if (albumID >= categoryLength) albumID = albumID - categoryLength;

	return albumID;
}

function pause(state) {
	state.isPlaying = false;
	localStorage.removeItem('isPlaying');
	localStorage.removeItem('playPressed');
}

function toggle(state) {
	if (state.isPlaying) pause(state);
	else play(state);
}

const next = (state) => switchAlbum(state, 'next');

const prev = (state) => switchAlbum(state, 'previous');

function switchAlbum(state, direction) {
	const selectedCategoryLength = state.discography[state.selectedCategory].length;

	if (direction === 'next') {
		if (state.currentAlbum[state.selectedCategory] >= selectedCategoryLength - 1) {
			state.currentAlbum[state.selectedCategory] = 0;
		} else state.currentAlbum[state.selectedCategory]++;
	}

	if (direction === 'previous') {
		if (state.currentAlbum[state.selectedCategory] <= -2) {
			state.currentAlbum[state.selectedCategory] = selectedCategoryLength - 3;
		} else state.currentAlbum[state.selectedCategory]--;
	}

	localStorage.setItem(state.selectedCategory, state.currentAlbum[state.selectedCategory]);

	defineDuration(state);
	playIfPlayed(state);
}

const language = (state, payload) => {
	if (payload) {
		state.siteLang = payload;
		localStorage.setItem('siteLang', payload);
		window.history.replaceState('', '', '/');
	} else {
		if (state.siteLang == 'ru') state.siteLang = 'en';
		else state.siteLang = 'ru';
	}

	if (!state.isPlaying) {
		document.title = state.pageTitle[state.siteLang];
	}

	localStorage.setItem('siteLang', state.siteLang);
};

const dissolveLoadScreen = (state) => {
	if (!state.isLoaded) state.isLoaded = true;
	localStorage.setItem('isLoaded', state.isLoaded);
};

const discography = (state, albumID) => {
	albumID = normalizeAlbumId(albumID);

	state.showDiscography = true;
	state.discographyAlbum = albumID - 1;

	localStorage.setItem('discographyAlbum', state.discographyAlbum);
};

function normalizeAlbumId(id) {
	if (id > 59) {
		id = 59;
	} else if (id < 1) {
		id = 1;
	}
	return id;
}

function playIfPlayed(state) {
	if (state.isPlaying) {
		nextTick(() => {
			document.querySelector('audio')?.play();
		});
	}
}

function playIfPaused(state) {
	if (!state.isPlaying) {
		nextTick(() => {
			document.querySelector('audio')?.play();
		});
	}
}

function defineDuration(state) {
	const time = document.querySelector('audio')?.duration;
	setTotalTime(state, time);
}

function setElapsedTime(state, time) {
	state.elapsedTime = time;
	localStorage.setItem('elapsedTime', time);
}

function setData(state, data) {
	const { json, type } = data;

	if (type === 'links') {
		state['links'] = json[0];
		return;
	}

	if (type === 'pages') {
		state[type] = json;
		return;
	}

	state.discography[type] = json;
}

function setTotalTime(state, time) {
	state.totalTime = time;
	localStorage.setItem('totalTime', time);
}

function changeVolume(state, direction) {
	if (direction === 'up') {
		if (state.volume > 0.99) state.volume = 1;
		else state.volume = Math.round(parseFloat(state.volume) * 10) / 10 + 0.1;
	}
	if (direction === 'down') {
		if (state.volume < 0.01) state.volume = 0;
		else state.volume = Math.round(parseFloat(state.volume) * 10) / 10 - 0.1;
	}

	localStorage.setItem('volume', state.volume);
}

function changeCategory(state, category) {
	state.selectedCategory = category;
	localStorage.setItem('selectedCategory', category);
}

export default {
	changeCategory,
	changeVolume,
	discography,
	dissolveLoadScreen,
	initializeStore,
	language,
	next,
	pause,
	play,
	playIfPaused,
	prev,
	setData,
	setElapsedTime,
	setTotalTime,
	toggle,
};
