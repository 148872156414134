<template>
	<div class="description-wrapper">
		<div class="album-description" v-html="this.description"></div>
		<div class="album-links-wrapper">
			<div v-if="showLinksIntro" class="links-intro">{{ this.linksIntro[this.siteLang] }}</div>
			<div class="album-links">
				<AlbumLink v-for="(link, type, i) in this.albumLinks" :key="i" :href="link" :src="getIcon(type)" />
			</div>
		</div>
	</div>
</template>

<script>
import { toRaw } from 'vue';
import { mapGetters } from 'vuex';
import AlbumLink from './AlbumLink.vue';

export default {
	props: ['albumNumber'],

	components: { AlbumLink },

	computed: {
		...mapGetters(['currentAlbumInstance', 'links', 'siteLang', 'selectedCategoryLength', 'shiftedAlbumID', 'linksIntro']),

		description() {
			try {
				return this.currentAlbumInstance?.description[this.siteLang];
			} catch {
				return '';
			}
		},

		albumLinks() {
			try {
				return this.currentAlbumInstance?.links;
			} catch (error) {
				return false;
			}
		},

		showLinksIntro() {
			try {
				const linksInCurrentAlbum = Object.keys(toRaw(this.currentAlbumInstance.links));

				if (this.siteLang === 'ru') return linksInCurrentAlbum.includes('vk');
				else return linksInCurrentAlbum.includes('applemusic');
			} catch {
				return false;
			}
		},
	},

	methods: {
		getIcon(type) {
			try {
				if (type in this.links) {
					return this.links[type].icon;
				}
			} catch (e) {
				return false;
			}
		},
	},
};
</script>

<style scoped lang="scss">
.description-wrapper {
	margin-top: 20px;
	text-align: center;
}

.album-description {
	user-select: none;
	font-weight: 200;
	cursor: default;
	line-height: 125%;
	font-size: 1.15em;
}

.album-links-wrapper {
	display: flex;
	gap: 8px;
	justify-content: center;
	margin-bottom: 40px;
}

.album-links {
	display: flex;
	justify-content: center;
	align-items: baseline;
	gap: 8px;
	width: 25px;
	flex-wrap: wrap;
	position: relative;
	top: 8px;
	height: 29px;
	overflow: hidden;
}

.links-intro {
	margin-top: 10px;
	text-decoration: underline;
	font-weight: 200;
	user-select: none;
	cursor: default;
}
</style>
