const albumHasNoMusic = (state) => {
	try {
		const currentAlbum = { ...currentAlbums(state)[shiftedAlbumID(state)] };
		const playingAlbum = { ...playingAlbum(state) };
		return Object.keys(currentAlbum).length === 5 || Object.keys(currentAlbum).length === 0;
	} catch (e) {
		return false;
	}
};
const artistContacts = (state) => state.artist?.contacts;
const artistSpecialty = (state) => state.artist?.artistSpecialty?.[state.siteLang];
const currentAlbumInstance = (state) => {
	let albumID = shiftedAlbumID(state);
	if (typeof albumID == 'number') {
		return state.discography[state.selectedCategory][albumID];
	}
};
const currentAlbumNumber = (state) => state.currentAlbum[state.selectedCategory];
const currentAlbum = (state) => state.currentAlbum;
const currentAlbums = (state) => state.discography[state.selectedCategory];
const elapsedTime = (state) => state.elapsedTime;
const feedbackMail = (state) => state.feedbackMail;
const isLoaded = (state) => !state.isLoaded;
const isPlaying = (state) => state.isPlaying;
const links = (state) => state.links;
const linksIntro = (state) => state.linksIntro;
const modalOpened = (state) => state.showFeedback || state.showStory || state.showInfo;
const pages = (state) => state.pages;
const pageTitle = (state) => state.pageTitle?.[state.siteLang];
const playBtn = (state) => state.playBtn[state.siteLang];
const playingAlbum = (state) => state.playingAlbum;
const playPressed = (state) => state.playPressed;
const selectedCategory = (state) => state.selectedCategory;
const selectedCategoryLength = (state) => state.discography[state.selectedCategory]?.length;
const shiftedAlbumID = (state) => {
	const categoryLength = selectedCategoryLength(state);
	const offset = 2;
	let albumID = currentAlbumNumber(state) + offset;

	if (!categoryLength) return '';
	if (albumID >= categoryLength) albumID = albumID - categoryLength;

	return albumID;
};
const showFeedback = (state) => state.showFeedback;
const siteLang = (state) => state.siteLang;
const totalTime = (state) => state.totalTime;
const volume = (state) => state.volume;
const workshop = (state) => state.workshop;
const yourAlbumBtn = (state) => state.yourAlbumBtn[state.siteLang];
const yourAlbumTitles = (state) => {
	try {
		return state.yourAlbumTitles[selectedCategory(state)][siteLang(state)];
	} catch (error) {
		return false;
	}
};

export default {
	albumHasNoMusic,
	artistContacts,
	artistSpecialty,
	currentAlbum,
	currentAlbumInstance,
	currentAlbumNumber,
	currentAlbums,
	elapsedTime,
	feedbackMail,
	isLoaded,
	isPlaying,
	links,
	linksIntro,
	modalOpened,
	pages,
	pageTitle,
	playBtn,
	playingAlbum,
	playPressed,
	selectedCategory,
	selectedCategoryLength,
	shiftedAlbumID,
	showFeedback,
	siteLang,
	totalTime,
	volume,
	workshop,
	yourAlbumBtn,
	yourAlbumTitles,
};
