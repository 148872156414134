import mitt from 'mitt';
import { createApp, toRaw } from 'vue';
import App from './App.vue';
import store from './store';

const emitter = mitt();
const app = createApp(App);
app.config.globalProperties.emitter = emitter;
app.use(store);

function loadData() {
	const categories = toRaw(store.state.categories);
	const dataToLoad = Object.keys(categories);

	dataToLoad.push('links');
	dataToLoad.push('pages');

	try {
		for (const type of dataToLoad) {
			fetch(`../api/${type}.json`)
				.then((response) => response.json())
				.then((json) => {
					store.commit('setData', { json, type });
				});
		}
	} catch (e) {
		console.log(e);
	}
}

loadData();
app.mount('#app');
