<template>
	<div>
		<progress-bar v-show="showPassedDuration" class="pointer" @click="setElapsedTime" />
		<div v-show="!yourAlbum">
			<div ref="playBtn" @click="clicked('toggle')" class="play-title" :class="{ visible: showBtnTitle }" v-show="!playPressed">
				{{ playBtn }}
			</div>
			<player-icon
				ref="playIcon"
				@click="clicked('toggle')"
				class="main-btn"
				:class="{ invisible: showBtnTitle }"
				:iconType="'play'"
				v-show="!playPressed"
			/>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import PlayerIcon from './PlayerIcon.vue';
import ProgressBar from './ProgressBar.vue';

export default {
	props: ['centralAlbumHovered', 'albumHovered'],

	components: {
		ProgressBar,
		PlayerIcon,
	},

	methods: {
		clicked(action) {
			this.$store.dispatch(action);
		},

		setElapsedTime(e) {
			if (e) {
				const totalTimeClass = '.total';
				const progressBar = document.querySelector(totalTimeClass);
				const clickedTime = e.layerX;
				const fullTime = progressBar.clientWidth;

				const newtTime = (clickedTime / fullTime) * this.totalTime;
				const currentTime = document.querySelector('audio').currentTime;
				const difference = Math.abs(newtTime - currentTime);

				if (difference > 5) {
					document.querySelector('audio').currentTime = newtTime;
				}

				this._togglePlaying();
			}
		},

		_togglePlaying() {
			if (!this.isPlaying) this.$store.dispatch('play');
		},
	},

	computed: {
		...mapGetters([
			'currentAlbumInstance',
			'feedbackMail',
			'isPlaying',
			'playBtn',
			'playingTrack',
			'playPressed',
			'siteLang',
			'totalTime',
			'yourAlbumBtn',
		]),

		showBtnTitle() {
			return this.albumHovered && !this.yourAlbum;
		},

		showPassedDuration() {
			return this.isPlaying || this.playPressed;
		},

		bookingMailLink() {
			return `mailto:${this.email}?subject=${this.topic}`;
		},

		email() {
			return this.feedbackMail.email;
		},

		topic() {
			return this.feedbackMail.topic[this.siteLang];
		},

		yourAlbum() {
			if (!this.currentAlbumInstance?.links) {
				return this.yourAlbumBtn;
			}
			return false;
		},
	},
};
</script>
