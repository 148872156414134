<template>
	<header>
		<LanguageSwitcher />
		<div class="header-wrapper">
			<artist-name class="header-logo" />
			<ThePages />
			<the-links />
		</div>
	</header>
</template>

<script>
import ArtistName from '../Artist/ArtistName.vue';
import TheLinks from '../Links/TheLinks.vue';
import ThePages from '../Pages/ThePages.vue';
import LanguageSwitcher from './LanguageSwitcher.vue';

export default {
	components: { ArtistName, TheLinks, LanguageSwitcher, ThePages },
};
</script>

<style>
header {
	user-select: none;
	display: flex;
	justify-content: center;
	background: linear-gradient(
		12deg,
		rgba(98, 98, 98, 0.3) 0%,
		rgba(130, 130, 130, 0.3) 26.56%,
		rgba(81, 81, 81, 0.3) 78.65%,
		rgba(102, 102, 102, 0.3) 100%
	);
	border-bottom: 1px solid rgba(204, 204, 204, 0.3);
	width: 100vw;
	z-index: 10000;
	position: fixed;
}

.header-wrapper {
	justify-self: center;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 60px;
	gap: 20px;
	font-weight: 300;
	flex-wrap: wrap;
	margin: 0 20px;
}

.header-logo {
	transition: all 0.7s;
}

.header-logo:hover {
	transform: scale(1.05);
}

@media (max-width: 505px) {
	header {
		backdrop-filter: blur(3px);
	}

	.header-wrapper {
		padding: 30px 0;
		gap: 12px 15px;
	}

	.pages {
		height: 0;
	}

	.contacts.page-content {
		top: 120px;
		backdrop-filter: blur(3px);
		position: fixed;
	}
}
</style>
