<template v-cloak>
	<LoadScreen v-show="isLoaded" />
	<Transition name="appearLong">
		<MainView v-show="!isLoaded" />
	</Transition>
</template>

<script>
import { mapGetters } from 'vuex';
import LoadScreen from './components/LoadScreen.vue';
import MainView from './components/MainView.vue';
import { downArrow, leftArrow, rightArrow, spacebar, upArrow } from './eventListeners';

export default {
	components: {
		LoadScreen,
		MainView,
	},

	methods: {
		toggle(keyCode) {
			if (keyCode) this.emitter.emit(keyCode);
			this.$store.dispatch('toggle');
		},

		next(keyCode) {
			if (keyCode) this.emitter.emit(keyCode);
			this.$store.dispatch('next');
		},

		prev(keyCode) {
			if (keyCode) this.emitter.emit(keyCode);
			this.$store.dispatch('prev');
		},

		quieter(keyCode) {
			if (keyCode) this.emitter.emit(keyCode);
			this.$store.dispatch('changeVolume', 'down');
		},

		louder(keyCode) {
			if (keyCode) this.emitter.emit(keyCode);
			this.$store.dispatch('changeVolume', 'up');
		},

		goBack(keyCode) {
			if (keyCode) this.emitter.emit(keyCode);
			this.$store.dispatch('goBack');
		},

		language(lang) {
			this.$store.dispatch('language', lang);
		},

		dissolveLoadScreen() {
			this.$store.dispatch('dissolveLoadScreen');
		},
	},

	computed: {
		...mapGetters(['isLoaded', 'showFeedback', 'isPlaying', 'siteLang', 'pageTitle']),
	},

	beforeCreate() {
		this.$store.commit('initializeStore');
	},

	created() {
		document.title = this.pageTitle;

		const that = this;
		(function createMetaTags() {
			const stylesheet = document.querySelector('link');

			const ogTitle = document.createElement('meta');
			ogTitle.setAttribute('property', 'og:title');
			ogTitle.setAttribute('content', `${that.pageTitle}`);
			document.head.insertBefore(ogTitle, stylesheet);

			const ogURL = document.createElement('meta');
			ogURL.setAttribute('property', 'og:url');
			ogURL.setAttribute('url', 'https://linderebros.ru');
			document.head.insertBefore(ogURL, stylesheet);

			const ogType = document.createElement('meta');
			ogType.setAttribute('property', 'og:type');
			ogType.setAttribute('content', 'website');
			document.head.insertBefore(ogType, stylesheet);

			const twitterCard = document.createElement('meta');
			twitterCard.setAttribute('property', 'twitter:card');
			twitterCard.setAttribute('content', 'summary_large_image');
			document.head.insertBefore(twitterCard, stylesheet);
		})();
	},

	mounted() {
		const that = this;

		const userLanguage = navigator.language || navigator.userLanguage;
		if (userLanguage.toLowerCase().indexOf('ru') === 0) this.language('ru');

		(function initializeEventListeners() {
			spacebar(that.toggle);
			leftArrow(that.prev);
			rightArrow(that.next);
			upArrow(that.louder);
			downArrow(that.quieter);
		})(that);

		(function initializeEmitters() {
			that.emitter.on('KeyF', (e) => {
				if (e) that.feedback();
			});
			that.emitter.on('Escape', (e) => {
				if (e) that.goBack();
			});
			that.emitter.on('Space', (e) => {
				if (e) that.toggle();
			});
			that.emitter.on('ArrowUp', (e) => {
				if (e) that.louder();
			});
			that.emitter.on('ArrowDown', (e) => {
				if (e) that.quieter();
			});
			that.emitter.on('ArrowLeft', (e) => {
				if (e) that.prev();
			});
			that.emitter.on('ArrowRight', (e) => {
				if (e) that.next();
			});
		})(that);

		document.addEventListener('DOMContentLoaded', (e) => {
			const path = e.target.location.pathname.substring(1);
			switch (path) {
				case 'en':
					this.language('en');
					break;
				case 'ru':
					this.language('ru');
					break;
				default:
					break;
			}

			setTimeout(this.dissolveLoadScreen, 3000);
		});
	},
};
</script>

<style lang="scss">
:root {
	--selection: #6782c0;
	//cursor: url('data:image/svg+xml;utf8,<svg width="26" height="37" viewBox="0 0 26 37" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.34729 1.38025L23.9548 19.3454L21.9403 30.0006C21.9601 30.0002 21.9801 30 22 30C23.6569 30 25 31.3432 25 33C25 34.6569 23.6569 36 22 36C20.3431 36 19 34.6569 19 33C19 32.6294 19.0672 32.2744 19.1902 31.9466L8.45415 29.9169L0.34729 1.38025Z" fill="white" stroke="cornflowerblue" stroke-width="0.33"/></svg>'),
	//auto;
}

[v-cloak] {
	display: none;
}

::selection {
	background: var(--selection);
}

button {
	border: none;
	background: transparent;
	cursor: pointer;
	margin: 0;
	padding: 0;
	font-family: inherit;
	font-size: inherit;
	color: inherit;
	user-select: none;
}

@font-face {
	font-family: Oswald;
	src: url('../public/assets/fonts/Oswald.ttf') format('truetype');
	font-weight: 200 300 normal;
}

@font-face {
	font-family: Tangerine;
	src: url('../public/assets/fonts/Tangerine Regular.ttf') format('truetype');
	font-weight: normal;
}

@font-face {
	font-family: Tangerine;
	src: url('../public/assets/fonts/Tangerine Bold.ttf') format('truetype');
	font-weight: bold;
}

body {
	background: url('../public/assets/images/bg.jpg') no-repeat center center fixed;
	background-size: cover;
	font-family: 'Oswald', sans-serif;
	font-size: 16px;
	color: white;
	margin: 0;
}

.grayscale {
	filter: grayscale(1);
	transition: all 0.7s;
}

.grayscale:hover {
	filter: grayscale(0);
	transform: scale(1.1);
}

.surface-enter-active {
	animation: surfaceIn 1.5s;
}

.surface-leave-active {
	animation: surfaceOut 1.5s;
}

@keyframes surfaceIn {
	from {
		opacity: 0;
		transform: translateY(300px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes surfaceOut {
	from {
		opacity: 1;
		transform: translateY(0);
	}
	to {
		opacity: 0;
		transform: translateY(100px);
	}
}

.appear-enter-active {
	animation: appearIn 1s;
}

.appear-leave-active {
	animation: appearOut 0s;
}

@keyframes appearIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes appearOut {
	from {
		opacity: 0;
	}
	to {
		opacity: 0;
	}
}

.appearFull-enter-active {
	animation: appearFullIn 1.25s;
}

.appearFull-leave-active {
	animation: appearFullOut 1.25s;
}

@keyframes appearFullIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes appearFullOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

.appearLong-enter-active {
	animation: appearLongIn 5s;
}

.appearLong-leave-active {
	animation: appearLongOut 5s;
}

@keyframes appearLongIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes appearLongOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

.pulsation {
	animation: pulsation 0.7s alternate infinite ease-in-out;
}

@keyframes pulsation {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(1.05);
	}
}

.pulsing-stars {
	transition: all 1s;
}

.pulsing-stars::after,
.pulsing-stars::before {
	animation: pulsation-big 0.7s alternate infinite ease-in-out;
}

@keyframes pulsation-big {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(1.2);
	}
}
</style>
