const play = (context, payload) => context.commit('play', payload);

function pause(context) {
	document.querySelector('audio')?.pause();
	context.commit('pause');
}

const next = (context) => context.commit('next');

const prev = (context) => context.commit('prev');

function toggle(context, payload) {
	const isPressedOnAnotherAlbum = payload - 3 != context.state.currentAlbum[context.state.selectedCategory];

	if (payload && isPressedOnAnotherAlbum) {
		play(context, payload);
		return;
	}

	if (context.getters.isPlaying) {
		pause(context);
		return;
	}

	play(context, payload);
}

const jumpToAlbum = (context, albumID) => context.commit('jumpToAlbum', albumID);

const language = (context, payload) => {
	if (payload) context.commit('language', payload);
	else context.commit('language');
};

const feedback = (context) => context.commit('feedback');

const dissolveLoadScreen = (context) => context.commit('dissolveLoadScreen');

const discography = (context, albumID) => context.commit('discography', albumID);

const setTotalTime = (context, payload) => context.commit('setTotalTime', payload);

const setElapsedTime = (context, payload) => context.commit('setElapsedTime', payload);

const changeVolume = (context, payload) => context.commit('changeVolume', payload);

const changeCategory = (context, payload) => context.commit('changeCategory', payload);

export default {
	changeCategory,
	changeVolume,
	close,
	discography,
	dissolveLoadScreen,
	feedback,
	jumpToAlbum,
	language,
	next,
	pause,
	play,
	prev,
	setElapsedTime,
	setTotalTime,
	toggle,
};
