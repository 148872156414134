<template>
	<div class="page">
		<div @click="showPageContent(page.type)" class="page-name">{{ page[this.siteLang] }}</div>
		<PageContent class="page-content" :class="page.type" :content="content" :type="pageClass" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import PageContent from './PageContent.vue';

export default {
	props: ['page'],
	components: { PageContent },

	computed: {
		...mapGetters(['siteLang', 'workshop', 'artistContacts']),

		content() {
			if (this.page.type === 'workshop') {
				return this.workshop;
			} else {
				return this.artistContacts;
			}
		},

		pageClass() {
			if (this.page.type === 'workshop') return 'workshop';
			else return 'contacts';
		},
	},

	methods: {
		showPageContent(pageType) {
			const pageContents = document.querySelectorAll('.page-content');
			pageContents.forEach((content) => {
				if (content.classList.contains(pageType)) {
					content.classList.toggle('visible');
				} else {
					content.classList.remove('visible');
				}
			});
		},
	},
};
</script>

<style lang="scss">
.page {
	height: 100%;
	display: flex;
	align-items: center;
	cursor: pointer;
	transition: all 0.7s;
	position: relative;
}

.page:hover {
	.page-name {
		text-decoration: underline;
		transform: scale(1.02);
	}
}

.page-content {
	display: none;
	cursor: default;
	opacity: 0;
	position: absolute;
}

.visible {
	opacity: 1;
	z-index: 10000;
	display: block;
}
</style>
