<template>
	<main>
		<the-header />
		<div class="content">
			<div class="main-titles">
				<artist-name class="main-title" />
				<artist-specialty ref="artist-speiciality" class="main-subtitle" />
			</div>
			<the-categories />
			<the-player />
			<album-description :albumNumber="this.albumNumber" />
		</div>
	</main>
</template>

<script>
import { mapGetters } from 'vuex';
import AlbumDescription from './Album/AlbumDescription.vue';
import ArtistName from './Artist/ArtistName.vue';
import ArtistSpecialty from './Artist/ArtistSpecialty.vue';
import TheCategories from './Categories/TheCategories.vue';
import TheHeader from './Header/TheHeader.vue';
import ThePlayer from './Player/ThePlayer.vue';

export default {
	components: {
		AlbumDescription,
		ArtistName,
		ArtistSpecialty,
		TheCategories,
		ThePlayer,
		TheHeader,
	},

	computed: {
		...mapGetters(['currentAlbumInstance', 'selectedCategoryLength']),

		albumNumber() {
			return '';
		},

		artistSpecialtyClasses() {
			return this.$refs['artist-specialty'].$el.classList;
		},
	},
};
</script>

<style lang="scss">
main {
	height: 100vh;
	position: relative;
	display: flex;
	flex-direction: column;
}

.content {
	height: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
	align-items: center;
}

.main-titles {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 110px;
}

.main-title {
	font-size: 5.1em;
	cursor: default;
}

.main-subtitle {
	font-size: 1.3em;
	position: relative;
	top: -15px;
	cursor: default;
}

.main-subtitle::before,
.main-subtitle::after {
	background-image: url('/public/assets/icons/star.svg');
	background-repeat: no-repeat;
	background-size: 16px 16px;
	content: '';
	display: inline-block;
	height: 16px;
	margin: 0 5px;
	position: relative;
	top: 2px;
	width: 16px;
}

.main-subtitle::after {
	margin: 0 4px;
}

@media (max-width: 505px) {
	.main-titles {
		margin-top: 160px;
	}
}
</style>
