<template>
	<div class="pages">
		<the-page v-for="page in this.pages" :key="page" :page="page" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import ThePage from './ThePage.vue';

export default {
	components: { ThePage },

	computed: {
		...mapGetters(['pages']),
	},
};
</script>

<style>
.pages {
	text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
	font-family: Oswald;
	font-weight: 300;
	display: flex;
	height: 100%;
	gap: 8px;
	align-items: center;
}
</style>
