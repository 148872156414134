<template>
	<div class="player-items">
		<div class="albums-wrapper">
			<the-album v-for="i in 5" :key="i" :order="i - 1" />
		</div>
	</div>
</template>

<script>
import TheAlbum from './TheAlbum.vue';

export default {
	components: {
		TheAlbum,
	},
};
</script>

<style lang="scss">
.player-items {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.albums-wrapper {
	display: flex;
	gap: 10px;
	align-items: end;
	flex-wrap: wrap;
}

.albums-wrapper div:first-of-type,
.albums-wrapper div:last-of-type {
	position: relative;
	top: 50px;

	.image {
		width: 66px;
		height: 66px;
		border-radius: 15px;
		border-width: 1px;
	}
}

.albums-wrapper div:nth-of-type(2),
.albums-wrapper div:nth-of-type(4) {
	position: relative;
	top: 25px;

	.image {
		width: 133px;
		height: 133px;
		border-radius: 20px;
		border-width: 1.5px;
	}
}

.player-items {
	.prev,
	.next {
		opacity: 0.8;
		width: 44px;
	}

	svg {
		width: 22px;
	}
}

.player-items {
	.prev:active,
	.prev:hover,
	.next:active,
	.next:hover {
		opacity: 1;
	}
}

.player-item {
	transition: all 1s;
	position: relative;
	user-select: none;
	cursor: pointer;
	margin: 0 8px;

	&:hover .icon,
	&:active .icon {
		opacity: 1;
	}

	.icon {
		opacity: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		height: 44px;
		width: 33px;
	}
}

.image {
	box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.25);
	border: 2px solid #ffffff;
	border-radius: 28px;
	width: 200px;
	height: 200px;
}

.full {
	margin: 0;
	max-width: 720px;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
}
</style>
