<template>
	<div class="links">
		<the-link
			class="grayscale"
			v-for="(data, i) in links"
			:key="i"
			:href="data.link[siteLang]"
			:title="data.title[siteLang]"
			:src="data.icon"
		/>
	</div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import TheLink from './TheLink.vue';
const store = useStore();
const links = computed(() => store.state.links);
const siteLang = computed(() => store.state.siteLang);
</script>

<style>
.links {
	display: flex;
	gap: 10px;
	position: relative;
	top: 3px;
}
</style>
