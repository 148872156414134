<template>
	<button class="close-button">
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M0.93934 16.9098C0.353553 17.4956 0.353553 18.4453 0.93934 19.0311C1.52513 19.6169 2.47487 19.6169 3.06066 19.0311L9.98528 12.1065L16.91 19.0312C17.4958 19.617 18.4455 19.617 19.0313 19.0312C19.6171 18.4454 19.6171 17.4957 19.0313 16.9099L12.1066 9.98519L18.617 3.47478C19.2028 2.889 19.2028 1.93925 18.617 1.35346C18.0312 0.767677 17.0815 0.767677 16.4957 1.35346L9.98528 7.86387L3.47494 1.35353C2.88915 0.767745 1.9394 0.767744 1.35362 1.35353C0.767829 1.93932 0.76783 2.88907 1.35362 3.47485L7.86396 9.98519L0.93934 16.9098Z"
				fill="white"
				fill-opacity="0.8"
			/>
		</svg>
	</button>
</template>
