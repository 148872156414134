<template>
	<div class="artist-specialty">{{ this.artistSpecialty }}</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	computed: {
		...mapGetters(['artistSpecialty']),
	},
};
</script>

<style lang="scss">
.artist-specialty {
	cursor: pointer;
	font-family: 'Oswald';
	font-size: 2em;
	user-select: none;
	font-weight: 200;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
}
</style>
