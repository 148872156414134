<template>
	<a v-if="showLink" class="album-link" :href="href" target="_blank">
		<img class="link_img" :src="src" alt="" />
	</a>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	props: ['src', 'href'],

	computed: {
		...mapGetters(['siteLang']),
		showLink() {
			if (this.siteLang == 'ru') {
				return this.src && this.href.toLowerCase().indexOf('vk.com') >= 0;
			} else {
				return this.src && this.href.toLowerCase().indexOf('apple.com') >= 0;
			}
		},
	},
};
</script>

<style lang="scss">
.album-link {
	position: relative;
	top: 4px;
	animation: pulse 3s alternate infinite ease-in;

	&:hover {
		animation-play-state: paused;
	}
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
	}
	50% {
		transform: scale(1.05);
	}

	100% {
		transform: scale(0.95);
	}
}

.link_img {
	width: 22px;
	transition: all 0.7s;
}

.link_img:hover {
	transform: scale(1.1);
}
</style>
