<template>
	<button @click="clicked(iconType)" :ref="iconType" class="player-icon pointer" :class="playerIconClasses" v-html="svg(iconType)"></button>
</template>
<script>
export default {
	props: {
		iconType: {
			type: String,
			required: true,
		},
	},
	methods: {
		clicked(iconType) {
			this.$emit('clicked', iconType);

			const that = this;
			(function toggleOpacity() {
				const itIsATouchDevice = navigator.maxTouchPoints;
				if (itIsATouchDevice) {
					const icon = that.$refs[iconType];
					icon.style.opacity = 1;
					setTimeout(() => {
						icon.style.opacity = 0.5;
					}, 175);
				}
			})(that);
		},
	},
	computed: {
		playerIconClasses() {
			return {
				play: this.iconType == 'play',
				pause: this.iconType == 'pause',
				close: this.iconType == 'close',
				support: this.iconType == 'support',
			};
		},
		svg() {
			return (icon) => {
				switch (icon) {
					case 'repeat':
						return `
							<svg viewBox="0 0 21 18" fill="none">
								<path
									d="M8.47324 11.6823V13.5865H14.8901C17.1018 13.5865 18.9002 11.7881 18.9002 9.5764C18.9002 9.13224 19.2598 8.77303 19.7036 8.77303C20.1483 8.77303 20.5069 9.1317 20.5069 9.5764C20.5069 12.673 17.9867 15.1932 14.8901 15.1932H8.47324V17.0975C8.47324 17.2965 8.35596 17.4763 8.17591 17.5566C8.11109 17.5851 8.04107 17.5999 7.97025 17.6C7.84731 17.5999 7.72663 17.555 7.6359 17.4725L4.62626 14.7638C4.52013 14.6678 4.45977 14.5319 4.45977 14.3899C4.45977 14.2468 4.52119 14.1106 4.62444 14.0178L7.63309 11.31C7.78 11.1776 7.99103 11.1437 8.17669 11.2232C8.3575 11.3054 8.47324 11.4852 8.47324 11.6823ZM12.0338 6.35028L11.9909 4.44607H5.61684C3.40661 4.44607 1.60674 6.24595 1.60674 8.45618C1.60674 8.90034 1.2471 9.25955 0.803368 9.25955C0.359635 9.25955 0 8.90034 0 8.45618C0 5.36028 2.52025 2.83934 5.61684 2.83934H12.0428L11.9999 0.931477C12.0016 0.766432 12.1208 0.566749 12.3454 0.436137C12.5247 0.364887 12.7308 0.400836 12.8733 0.528358L15.8808 3.23509C15.9931 3.33664 16.0471 3.44125 16.0471 3.60886C16.0471 3.78828 15.9852 3.90934 15.8718 3.99623L15.8593 4.00584L12.8396 6.72357C12.7449 6.80831 12.6238 6.85281 12.5024 6.85281C12.4934 6.85281 12.4701 6.85113 12.4349 6.84316C12.402 6.83569 12.3655 6.82422 12.3296 6.80905C12.1491 6.72678 12.0338 6.54714 12.0338 6.35028Z"
									fill="white"/>
							</svg>
						`;
					case 'play':
						return `
							<svg viewBox="0 0 34 38" fill="none">
								<path
									d="M31.6346 15.9448C33.5965 17.1066 33.5965 19.9458 31.6346 21.1076L5.42249 36.6292C3.42268 37.8134 0.893921 36.372 0.893921 34.0478V3.00458C0.893921 0.680446 3.42267 -0.760991 5.42249 0.423209L31.6346 15.9448Z"
									fill="white"
								/>
							</svg>
						`;
					case 'playFlipped':
						return `
							<svg viewBox="0 0 34 38" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M2.36528 15.9448C0.403378 17.1066 0.403378 19.9458 2.36528 21.1076L28.5774 36.6292C30.5772 37.8134 33.106 36.372 33.106 34.0478V3.00458C33.106 0.680446 30.5772 -0.760991 28.5774 0.423209L2.36528 15.9448Z" fill="white"/>
							</svg>
						`;
					case 'pause':
						return `
							<svg viewBox="0 0 22 28" fill="none">
								<rect x="0.0460205" y="0.211182" width="8.5" height="27" rx="2" fill="white" />
								<rect x="12.546" y="0.211182" width="8.5" height="27" rx="2" fill="white" />
							</svg>
						`;
					case 'prev':
						return `
							<svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M19.7374 0C19.1873 0 18.7289 0.262614 18.1133 0.598176L1.61095 9.48328C0.445302 10.1106 0 10.7818 0 11.6863C0 12.5909 0.445302 13.262 1.61095 13.8894L18.1133 22.7745C18.742 23.1246 19.2004 23.3872 19.7505 23.3872C20.8113 23.3872 21.5448 22.4827 21.5448 21.0383V2.33435C21.5448 0.88997 20.7982 0 19.7374 0Z" fill="#DBDBDB"/>
								<path d="M27.8163 4C27.4312 4 27.1103 4.18383 26.6794 4.41872L15.1277 10.6383C14.3117 11.0774 14 11.5472 14 12.1804C14 12.8136 14.3117 13.2834 15.1277 13.7225L26.6794 19.9421C27.1195 20.1872 27.4404 20.371 27.8254 20.371C28.568 20.371 29.0814 19.7379 29.0814 18.7268V5.63404C29.0814 4.62298 28.5589 4 27.8163 4Z" fill="#DBDBDB"/>
							</svg>
						`;
					case 'next':
						return `
							<svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M9.80757 23.3872C10.3576 23.3872 10.816 23.1246 11.4316 22.789L27.934 13.9039C29.0996 13.2766 29.5449 12.6054 29.5449 11.7009C29.5449 10.7963 29.0996 10.1252 27.934 9.49784L11.4316 0.612736C10.8029 0.262585 10.3445 -3.05176e-05 9.79447 -3.05176e-05C8.7336 -3.05176e-05 8.00016 0.90453 8.00016 2.34891L8.00016 21.0529C8.00016 22.4972 8.7467 23.3872 9.80757 23.3872Z" fill="#DBDBDB"/>
								<path d="M1.2648 20.3711C1.64986 20.3711 1.97074 20.1873 2.40164 19.9524L13.9534 13.7328C14.7693 13.2937 15.0811 12.8239 15.0811 12.1907C15.0811 11.5575 14.7693 11.0877 13.9534 10.6486L2.40164 4.42898C1.96157 4.18388 1.64069 4.00005 1.25563 4.00005C0.51302 4.00005 -0.000391006 4.63324 -0.000391006 5.6443L-0.000391006 18.7371C-0.000391006 19.7481 0.522187 20.3711 1.2648 20.3711Z" fill="#DBDBDB"/>
							</svg>
						`;
					case 'close':
						return `
							<svg viewBox="0 0 21 21" fill="none">
								<path
									d="M20.3848 0.585786C19.6037 -0.195262 18.3374 -0.195262 17.5564 0.585787L10.4853 7.65687L3.41421 0.585789C2.63316 -0.195259 1.36683 -0.195259 0.585786 0.585789C-0.195262 1.36684 -0.195262 2.63317 0.585787 3.41422L7.65686 10.4853L0.585808 17.5563C-0.195241 18.3374 -0.195241 19.6037 0.585808 20.3848C1.36686 21.1658 2.63319 21.1658 3.41424 20.3848L10.4853 13.3137L17.5563 20.3848C18.3374 21.1658 19.6037 21.1658 20.3848 20.3848C21.1658 19.6037 21.1658 18.3374 20.3848 17.5564L13.3137 10.4853L20.3848 3.41421C21.1658 2.63316 21.1658 1.36683 20.3848 0.585786Z"
									fill="white"
								/>
								</svg>
						`;
					case 'volumeDown':
						return `
							<svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M0 9.2117C0 6.60276 2.11497 4.48779 4.72391 4.48779H14.6441V16.2976H4.72391C2.11496 16.2976 0 14.1826 0 11.5737V9.2117Z" fill="white"/>
								<path fill-rule="evenodd" clip-rule="evenodd" d="M15.9757 0.000244141L8.10254 4.49369V13.3847H8.10274V16.2921L15.9759 20.7856V7.40111H15.9757V0.000244141Z" fill="white"/>
								<path d="M18.9873 3.39258C18.9873 3.39258 20.9873 5.39258 20.9873 9.89258C20.9873 14.3926 18.9873 16.8926 18.9873 16.8926" stroke="white" stroke-width="0.899996" stroke-linecap="round"/>
							</svg>
						`;
					case 'volumeUp':
						return `
							<svg width="30" height="25" viewBox="0 0 30 25" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M3 11.2117C3 8.60276 5.11497 6.48779 7.72391 6.48779H17.6441V18.2976H7.72391C5.11496 18.2976 3 16.1826 3 13.5737V11.2117Z" fill="white"/>
								<path fill-rule="evenodd" clip-rule="evenodd" d="M18.9757 2.00024L11.1025 6.49369V15.3847H11.1027V18.2921L18.9759 22.7856V9.40111H18.9757V2.00024Z" fill="white"/>
								<path d="M23.4873 3.39258C23.4873 3.39258 26.4873 5.72758 26.4873 11.8926C26.4873 18.0576 23.4873 21.3926 23.4873 21.3926" stroke="white" stroke-width="0.899996" stroke-linecap="round"/>
								<path d="M25.4873 0.892578C25.4873 0.892578 29.4365 3.69378 29.4365 11.9426C29.4365 20.1914 25.4873 23.8926 25.4873 23.8926" stroke="white" stroke-width="0.899996" stroke-linecap="round"/>
								<path d="M21.9873 5.39258C21.9873 5.39258 23.9873 7.39258 23.9873 11.8926C23.9873 16.3926 21.9873 18.8926 21.9873 18.8926" stroke="white" stroke-width="0.899996" stroke-linecap="round"/>
							</svg>
						`;
				}
			};
		},
	},
};
</script>

<style lang="scss">
.player-icon {
	width: 30px;
	opacity: 0.5;
	user-select: none;
	transition: all 0.5s;
	z-index: 200;

	&_big {
		width: 32px;
		height: 36px;
	}

	&:active {
		position: relative;
		top: 56px;
	}
}

@media (hover: hover) {
	.player-icon {
		&:hover,
		&:active {
			opacity: 1;
		}
	}
}

.close {
	position: fixed;
	height: 27px;
	top: 36px;
	right: 36px;

	&:active {
		position: fixed;
		top: 35px;
	}
}

.prev,
.next {
	top: 55px;
}

.play {
	margin-left: 3px;
	margin-top: 2px;
}

.icon-play-hovered svg {
	width: 25px !important;
}
</style>
