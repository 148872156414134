export default {
	artist: {
		fullName: {
			en: 'Lind Erebros',
			ru: 'Lind Erebros',
		},

		artistSpecialty: {
			en: 'music for games & films',
			ru: 'музыка для игр и кино',
		},

		contacts: [
			{
				name: 'Email',
				link: 'linderebros@list.ru',
			},
			{
				name: 'Skype',
				link: 'linderebros',
			},
		],
	},

	categories: {
		films: { en: 'films', ru: 'кино' },
		games: { en: 'games', ru: 'игры' },
		albums: { en: 'albums', ru: 'альбомы' },
	},

	currentAlbum: {
		films: -2,
		games: 0,
		albums: 0,
	},

	discography: [],

	elapsedTime: 0,

	feedbackMail: {
		email: 'linderebros@list.ru',
		topic: { en: 'Hello, Lind! We are from your website...', ru: 'Привет, Линд! Мы с твоего сайта...' },
	},

	isLoaded: false,

	isPlaying: false,

	linksIntro: {
		en: 'Listen to the album:',
		ru: 'Послушать альбом целиком бесплатно:',
	},

	pageTitle: {
		en: 'Lind Erebros — Official Website of the Composer',
		ru: 'Lind Erebros — Официальный сайт композитора',
	},

	playBtn: {
		en: 'Play',
		ru: 'Включить',
	},

	playingAlbum: { films: 0 },

	playPressed: false,

	selectedCategory: 'films',

	siteLang: 'en',

	totalTime: NaN,

	visited: false,

	volume: 0.4,

	workshop: [
		'Nekki 2008',
		'Shadow Fight 2 Excellence in Audio',
		'ДОМ РАДИО',
		'Егорьевск Пасха',
		'Запись 28 Панфиловцев',
		'Золотой Феникс 28 Панфиловцев',
		'Золотой Феникс 2017',
		'КРИ 2008',
		'МОСФИЛЬМ',
		'Невафильм',
		'Ночь Оперы в Метро Эльфийская Оратория',
		'Премьера 28 Панфиловцев',
		'Премьера 28 Панфиловцев 2',
		'Студия',
	],

	yourAlbumBtn: {
		en: 'Write me',
		ru: 'Заказать',
	},

	yourAlbumTitles: {
		films: {
			en: `Your cinema`,
			ru: `Ваш фильм`,
		},

		games: {
			en: `Your game`,
			ru: `Ваша игра`,
		},

		albums: {
			en: `In process`,
			ru: `Скоро`,
		},
	},
};
