export function initializeStore(state) {
	const stateItems = [
		'currentPhoto',
		'currentTrack',
		'currentVideo',
		'discographyAlbum',
		'elapsedTime',
		'isShuffled',
		'isLoaded',
		'playPressed',
		'repeat',
		'selectedCategory',
		'shuffledTrack',
		'siteLang',
		'visited',
		'volume',
	];

	stateItems.forEach((key) => {
		if (localStorage.getItem(key)) state[key] = localStorage.getItem(key);
	});

	for (let category in state.categories) {
		if (localStorage.getItem(category)) {
			state.currentAlbum[category] = parseInt(localStorage.getItem(category));
		}
	}

	if (localStorage.getItem('isPlaying') == 'true') {
		state.isPlaying = true;
		state.totalTime = localStorage.getItem('totalTime');
		state.elapsedTime = localStorage.getItem('elapsedTime');
	}

	if (localStorage.getItem('playingAlbum')) {
		state.playingAlbum[state.selectedCategory] = parseInt(localStorage.getItem('playingAlbum'));
	}
}
