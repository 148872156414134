function escapeKey(cb) {
	document.addEventListener('keyup', (e) => {
		if (e.code === 'Escape') {
			e.preventDefault();
			cb(e.code);
		}
	});
}

function spacebar(cb) {
	document.addEventListener('keyup', (e) => {
		if (e.code === 'Space') {
			e.preventDefault();
			cb(e.code);
		}
	});
}

function leftArrow(cb) {
	document.addEventListener('keyup', (e) => {
		if (e.code === 'ArrowLeft') {
			e.preventDefault();
			cb(e.code);
		}
	});
}

function rightArrow(cb) {
	document.addEventListener('keyup', (e) => {
		if (e.code === 'ArrowRight') {
			e.preventDefault();
			cb(e.code);
		}
	});
}

function upArrow(cb) {
	document.addEventListener('keyup', (e) => {
		if (e.code === 'ArrowUp') {
			e.preventDefault();
			cb(e.code);
		}
	});
}

function downArrow(cb) {
	document.addEventListener('keyup', (e) => {
		if (e.code === 'ArrowDown') {
			e.preventDefault();
			cb(e.code);
		}
	});
}

function backspace(cb) {
	document.addEventListener('keyup', (e) => {
		if (e.code === 'Backspace') {
			e.preventDefault();
			cb(e.code);
		}
	});
}

export { backspace, downArrow, escapeKey, leftArrow, rightArrow, spacebar, upArrow };
