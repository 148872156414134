<template>
	<div class="artist-name">{{ artistName[siteLang] }}</div>
</template>

<script setup>
import { useStore } from 'vuex';
const store = useStore();
const artistName = store.state.artist.fullName;
const siteLang = store.state.siteLang;
</script>

<style lang="scss">
.artist-name {
	cursor: pointer;
	font-family: 'Tangerine';
	font-size: 2em;
	user-select: none;
	font-weight: bold;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
}
</style>
