<template>
	<div class="categories">
		<the-category v-for="(category, i) in categories" :key="i" :category="category" />
	</div>
</template>

<script setup>
import { useStore } from 'vuex';
import TheCategory from './TheCategory.vue';
const store = useStore();
const categories = store.state.categories;
</script>

<style>
.categories {
	display: flex;
	gap: 10px;
	font-size: 1.2em;
	margin-top: 40px;
}
</style>
