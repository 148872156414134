<template>
	<div
		@mouseenter="albumHovered"
		@mouseleave="albumUnhovered"
		@click="albumClicked"
		class="player-item album pointer"
		:class="albumClasses"
	>
		<player-icon :iconType="'pause'" v-show="currentAlbumPlaying" class="icon" />
		<player-icon :iconType="'play'" v-show="showPlayIcon" class="icon icon-play-hovered" />
		<a v-if="youtubeLink" class="youtube-link" :href="youtubeLink" target="_blank">
			<img class="image" :src="cover" :alt="this.description" :title="this.description" />
			<span class="unreleased-album" v-if="hasAlbum">{{ this.unreleasedTitle }}</span>
		</a>
		<img v-else class="image" :src="cover" :alt="this.description" :title="this.title" />
		<div class="no-album-description" v-if="!cover">{{ this.title }}</div>
	</div>
</template>

<script>
import { toRaw } from 'vue';
import { mapGetters } from 'vuex';
import PlayerIcon from '../Player/PlayerIcon.vue';

export default {
	props: ['order'],

	components: { PlayerIcon },

	methods: {
		albumClicked() {
			const clickedAlbumIsPlaying = this.currentAlbumPlaying;
			if (clickedAlbumIsPlaying) {
				this.$store.dispatch('pause');
				return;
			}

			if (!this.hasAlbum || this.youtubeLink) {
				if (this.order != 2) this.$store.dispatch('pause');
				this.$store.dispatch('play', this.albumNumber);
				return;
			}

			if (this.isPlaying) {
				this.$store.dispatch('pause');
			}

			if (!this.isPlaying) {
				this.$store.dispatch('play', this.albumNumber);
			}
		},

		albumHovered() {
			this.$parent.$emit('albumHovered', { order: this.order, playable: !!this.hasAlbum });
		},

		albumUnhovered() {
			this.$parent.$emit('albumUnhovered', { order: this.order, playable: !!this.hasAlbum });
		},
	},

	computed: {
		...mapGetters([
			'currentAlbumNumber',
			'currentAlbum',
			'currentAlbums',
			'feedbackMail',
			'isPlaying',
			'playingAlbum',
			'selectedCategory',
			'selectedCategoryLength',
			'siteLang',
			'yourAlbumTitles',
		]),

		cover() {
			const path = `${window.location.origin}/assets/images`;
			const folderName = this.selectedCategory;
			const extension = 'jpg';

			return `${path}/${folderName}/${this.albumNumber}.${extension}`;
		},

		currentAlbum() {
			try {
				return this.currentAlbums[this.albumNumber - 1];
			} catch (e) {
				return false;
			}
		},

		youtubeLink() {
			try {
				return this.currentAlbum.links?.youtube;
			} catch (error) {
				return false;
			}
		},

		playable() {
			try {
				return this.currentAlbum.links?.vk;
			} catch (error) {
				return false;
			}
		},

		email() {
			return this.feedbackMail.email;
		},

		topic() {
			return this.feedbackMail.topic[this.siteLang];
		},

		description() {
			try {
				return this.currentAlbum.description[this.siteLang].trim();
			} catch {
				return '';
			}
		},

		title() {
			try {
				return this.currentAlbum.title[this.siteLang]?.trim() || '';
			} catch (e) {
				return this.yourAlbumTitles;
			}
		},

		unreleasedTitle() {
			const title = this.siteLang == 'en' ? 'Soon' : 'Скоро';

			try {
				if (this.currentAlbum.unreleased) return title;
				else return false;
			} catch (error) {
				return false;
			}
		},

		albumNumber() {
			const _correctNumber = (number) => {
				const fallbackCategoryLengthValue = 3;
				const selectedCategoryLength = this.selectedCategoryLength || fallbackCategoryLengthValue;
				if (number <= 0) {
					return selectedCategoryLength - -number;
				}
				if (number > selectedCategoryLength) {
					return number - selectedCategoryLength;
				}
				return number;
			};

			return _correctNumber(this.currentAlbumNumber + 1 + this.order);
		},

		hasAlbum() {
			try {
				const linksInCurrentAlbum = Object.keys(toRaw(this.currentAlbums[this.albumNumber - 1].links));
				const isUpcomingAlbum = linksInCurrentAlbum.includes('youtube');
				return linksInCurrentAlbum.length || !isUpcomingAlbum;
			} catch (e) {
				return false;
			}
		},

		albumClasses() {
			return {
				paused: !this.currentAlbumPlaying,
				main: this.order == 2,
				diff1: this.order == 1 || this.order == 3,
				diff2: this.order == 0 || this.order == 4,
			};
		},

		showPlayIcon() {
			return !this.unreleasedTitle && this.hasAlbum && !this.currentAlbumPlaying;
		},

		currentAlbumPlaying() {
			// return value: boolean

			const playingCategory = Object.keys(this.playingAlbum)[0];
			const somethingIsPlaying = this.isPlaying;
			const selectedCategory = this.selectedCategory;
			const playingAlbumId = Object.values(this.playingAlbum)[0];
			const currentAlbumId = Object.values(this.currentAlbum)[0] - 1;
			const currentAlbumHasMusic = this.hasAlbum;

			const currentAlbumIsPlaying =
				somethingIsPlaying && selectedCategory == playingCategory && currentAlbumHasMusic && playingAlbumId == currentAlbumId;
			return currentAlbumIsPlaying;
		},
	},
};
</script>

<style lang="scss">
.player-item {
	transition: all 0.5s;

	&:hover {
		transform: scale(1.02);
		.no-album-description {
			opacity: 1;
		}
	}
}

.no-album-description {
	transition: all 1.5s;
	position: absolute !important;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	line-height: 125%;
	text-transform: uppercase;
	top: 48% !important;
	opacity: 0.75;
	font-weight: 350;
	pointer-events: none;
}

.main .no-album-description {
	font-size: 2em;
}

.diff1 .no-album-description {
	font-size: 1.5em;
}

.unreleased-album {
	position: absolute !important;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	line-height: 125%;
	text-transform: uppercase;
	top: 70% !important;
	width: 100%;
	padding: 10px 0;
	font-weight: 400;
	color: black;
	background: white;
}

.main .unreleased-album {
	font-size: 1.75em;
}

.diff2 .unreleased-album {
	color: white;
	background: transparent;
}

.paused img {
	filter: brightness(85%);
}

.album .image {
	background: linear-gradient(193deg, #556a8984 0%, #555e8a7a 100%);
	background-size: cover;
}

.side-album {
	display: none;
	margin: 0 !important;
	filter: brightness(90%) !important;
	.image {
		width: 140px !important;
		height: auto !important;
	}
}

.nav-item:hover .icon,
.nav-item:active .icon {
	opacity: 1;
}

.icon {
	opacity: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 44px;
	width: 33px;
}

.paused {
	.pause.icon {
		opacity: 0;
		transition: all 1s;
	}
}

.paused:hover {
	.pause.icon {
		opacity: 1;
	}
}

.youtube-link {
	color: white;
}

@media (max-width: 840px) {
	.diff2 {
		display: none;
	}
}

@media (max-width: 690px) {
	.diff1 {
		display: none;
	}
}

@media (min-width: 675px) {
	.side-album {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
</style>
