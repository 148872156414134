<template>
	<div :class="{ hidden: isHidden }" class="total" ref="total">
		<player-icon @click="changeVolume($event, 'down')" :iconType="'volumeDown'" class="volume-down" />
		<div class="duration">{{ duration }}</div>
		<player-icon v-show="!this.isPlaying" @click="clicked($event, 'play')" :iconType="'play'" class="play-icon" />
		<player-icon v-show="this.isPlaying" @click="clicked($event, 'pause')" :iconType="'pause'" class="pause-icon" />
		<div v-show="showPassedDuration" class="current" :style="{ width: ratio * 100 + '%' }">
			<div ref="current__rod" class="current__rod"></div>
		</div>
		<player-icon @click="changeVolume($event, 'up')" :iconType="'volumeUp'" class="volume-up" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import PlayerIcon from './PlayerIcon.vue';

export default {
	components: { PlayerIcon },

	methods: {
		changeVolume($event, direction) {
			$event.stopPropagation();
			if (direction == 'up') {
				this.$store.dispatch('changeVolume', 'up');
			} else {
				this.$store.dispatch('changeVolume', 'down');
			}
		},

		clicked($event, action) {
			$event.stopPropagation();
			this.$store.dispatch(action);
		},
	},

	computed: {
		...mapGetters(['elapsedTime', 'totalTime', 'isPlaying', 'playPressed', 'volume']),

		ratio() {
			return this.elapsedTime / this.totalTime;
		},

		isHidden() {
			return isNaN(this.totalTime);
		},

		showPassedDuration() {
			return this.isPlaying || this.playPressed;
		},

		duration() {
			const minutes = parseInt(this.totalTime / 60);
			const seconds = parseInt(this.totalTime % 60);
			const secondsWithLeadingZero = seconds.toString().padStart(2, '0');
			return `${minutes}:${secondsWithLeadingZero}`;
		},
	},
};
</script>

<style scoped lang="scss">
.total {
	width: 100%;
	height: 100%;
	border-radius: 13px;
	overflow-x: hidden;
	opacity: 1;
	transition: opacity 1.25s;
	cursor: pointer;

	div {
		height: inherit;
		margin-left: 2px;
	}
}

.total:hover {
	.player-icon {
		opacity: 0.8 !important;
	}

	.duration {
		opacity: 0;
	}
}

.total .player-icon {
	transition: all 1.25s;
	opacity: 0;
	position: absolute;
	top: 7px;
	transform: scale(0.85);
}

.total .play-icon,
.total .pause-icon {
	transition: all 1.25s;
	transform: translateX(-50%) scale(0.55);
	top: -2px;
	left: 50%;
	opacity: 0;
}

.pause-icon:hover {
	transform: translateX(-50%) scale(0.605) !important;
	opacity: 1;
}

.total .play-icon {
	transform: translateX(-50%) scale(0.6);
}

.play-icon:hover {
	transform: translateX(-50%) scale(0.65) !important;
}

.total .play-icon:hover {
	transform: scale(0.93);
	opacity: 1;
}

.total .volume-down {
	left: 15px;
}

.total .volume-down:hover {
	transform: scale(0.93);
	opacity: 1;
}

.total .volume-up {
	right: 15px;
	top: 5px;
}

.total .volume-up:hover {
	transform: scale(0.93);
	opacity: 1;
}

.current {
	background-color: rgba(0, 0, 0, 0.15);
	transition: all 0.5s;
}

.current__rod {
	height: 100%;
	width: 2px;
	background-color: rgba(255, 255, 255, 0.45);
	width: 1px;
	float: right;
	position: relative;
	cursor: col-resize;
}

.duration {
	transition: all 1.25s;
	pointer-events: none;
	position: absolute;
	right: 0;
	line-height: 100%;
	display: flex;
	font-weight: 200;
	line-height: 100%;
	display: flex;
	padding-right: 9px;
	align-items: center;
	user-select: none;
}

.hidden {
	opacity: 0;
	transition: none;
}
</style>
